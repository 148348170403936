@import "./assets/styles/buttons/Following.css";
@import "./assets/styles/icon/arrow.css";
@import "./assets/styles/chips/Ranking.css";

.App {
  text-align: center;
  background-color: var(--white);
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: var(--white);
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.MuiPaper-root.MuiAccordion-root::before {
  background-color: var(--back);
}

.title-admin {
  /* KOL / Digital influencers */
  margin-top: 1rem;
  width: 20.375rem;
  height: 2.375rem;

  top: 7rem;
  margin-right: auto;
  margin-left: 1rem;

  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 38px;

  /* Primary - DarkBlue */
  color: var(--primary----dark-blue);
}

.tab-options-admin {
  z-index:1;
  cursor: pointer;
  margin-left: auto;
  margin-right: auto;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  width: 16.75rem;
  height: 2.625rem;
  margin-top: 1rem;
  justify-content: space-between;
  padding: 0.5rem;
  align-items: center;

  border: 1px solid var(--primary----blue);
  box-sizing: border-box;
  border-radius: 8px;
  background: var(--primary----blue-ultra-light);

  /* identical to box height, or 129% */
  display: flex;
  align-items: center;
  letter-spacing: 0.1px;

  /* Primary - Blue */
  color: var(--primary----blue);
  /* Primary - Blue 3 */
}

.tab-labels {
  background-color: var(--back);
  box-shadow: none;
}

.tab-button {
  background-color: var(--back);
  border-radius: 0;
  box-shadow: none;
}

.MuiTouchRipple-root {
  box-shadow: none;
  background: transparent;
}

.tab-button.Mui-selected {
  background: transparent;

  font-weight: 600;
  background-color: var(--back);
  box-shadow: none;
  border: none;
}

.tabs {
  margin-top: 0 !important;
}

.grid-table-admin {
  align-self: start;
  flex-direction: column;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-gap: 1rem;
  width: 100%;
  height: 100%;
  padding-left: 2rem;
  padding-right: 2rem;
  margin-left: auto;
  margin-right: auto;
}

.container-admin {
  background-color: --back;
  margin-top: 5.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.grid-filter-admin {
  z-index: 1;
  padding: 2rem;
  margin-top: -1rem;
  margin-left: -1rem;
  min-width: 23rem;
  width: 20vw;
  height: 100vh;
  display: flex;
  flex-direction: column!important;
  background: var(--white);
  margin-bottom: auto;
}
.title {
  /* KOL / Digital influencers */
  margin-top: 1rem;
  width: 27.375rem;
  height: 2.375rem;

  top: 7rem;
  margin-right: auto;
  margin-left: 2rem;

  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 38px;

  /* Primary - DarkBlue */
  color: var(--primary----dark-blue);
}

.tab-options {
    margin-top: 2rem;
    width: 50%;
    padding-right: auto;
    border-bottom: 0.5px solid var(--primary----blue-3);
    border-radius: 0;
    background-color: var(--back);
    height: auto;
    color: var(--back);
    text-align: left;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
  /* Primary - Blue 3 */

}

.tab-labels{
    background-color: var(--back);
    box-shadow: none;
}

.tab-button{
    background-color: var(--back);
    border-radius: 0;
    box-shadow: none;
}

.MuiTouchRipple-root {
    box-shadow: none;
    background: transparent;
    
}

.tab-button.Mui-selected{
    background: transparent;
    
    font-weight: 600;
    background-color: var(--back);
    box-shadow: none;
    border: none;
}



.grid-table{
    align-self: start;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding-left: 0;
    padding-right: 0;
    margin-left: 0;
    margin-right: 0;
    position: relative;
}

.container {
    position: absolute;
    height: 100vh;
    width: 100vw;
    background-color: var(--white);
    padding-top: 5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    top: 0;
    left: 0;
    overflow: hidden;
}
.grid-filter{
    margin-top: 57px;
    margin-left: 0rem;
    max-width: min-content;
    width: 100vw;
    height: min-content;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background: var(--back);
}

.tab-options-profile {
  margin-top: 2rem;
  padding-right: auto;
  border-bottom: 0.5px solid var(--primary----blue-3);
  border-radius: 0;
  background-color: var(--back);
  height: auto;
  color: var(--back);
  text-align: left;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
/* Primary - Blue 3 */

}

.link-back {
  display: flex;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: var(--primary----blue-1);
}

.box-profile{

  background: transparent;
  width:90%;

  height: 60%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  margin-left: 2rem;
  margin-right: auto;
  padding: 1rem;
  margin-bottom: auto;
}

.input-box{
  padding: 1rem;
  flex-direction: column;
  margin-top: 1rem !important;
  margin-bottom: auto;

  width: 100%;

 
}
.MuiTextField-root{
  background-color: inherit;
}

.Mui-disabled{
  background-color: inherit;
  color: red;

}

.input-icon{
  position: relative ;

  width: 7px;
  height: 7px;
  font-size: medium !important;
  margin-bottom: auto;
  margin-top: 0.8rem;
  margin-left: auto;
}

.grid-profile{
  margin-top:-1rem;
  margin-left: -1rem;
 
  width: 25vw;
  height: 92vh;
  display: flex;
  flex-direction: column;
  background: var(--back);
  margin-bottom: auto;
  overflow-y: auto;
  overflow-x: visible;
}

.MuiInputBase-multiline{

  padding-left: 0.9rem !important;
}

.edit-button{
  background-color: var(--light-gray---1) ;
  border-radius: 50%;
  border: 3px solid var(--light-gray---1);
  color: var(--primary----blue-1);
  margin-left: auto;
  margin-top: auto;
  margin-bottom: auto;
  font-size: smaller;
  width: 0.5rem;
}

.edit-button.selected{
  background-color: var(--primary----blue-3);
  border: 3px solid var(--primary----blue-3);
}

.Mui-disabled {
  background-color: inherit !important;
  color: inherit !important;
  -webkit-text-fill-color: inherit !important;
  
}


.header-profile {
  margin-top: 1rem;
  display: flex;
  color: var(--primary----dark-blue)
}

.title-profile {
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--primary----dark-blue);
  margin-left: 1rem;
  margin-right: 0.5rem;
  margin-top: auto;
  margin-bottom: auto;
  text-transform: capitalize;
}

.menu {
  display: flex;
  
  margin-right: auto;
  margin-left: 3rem;
  margin-top: 0.5rem;
  
}

.span {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  margin-left: 0.5rem;
  font-family: 'Open Sans', sans-serif;
  /* identical to box height */

  /* White */
  color: var(--white);
}

.span :a {
    text-decoration: none;
    color: var(--white);
    margin-left: 1rem;
}
.top-bar {
  position: absolute;
  width: 100vw;
  height: 80px;
  left: 0px;
  top: 0px;
  margin-top: 0px;
  padding-top: 0px;
  padding: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  z-index: 1;
  background-color: var(--primary----blue) !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  border-radius: 0px 0px 12px 12px;
  
}

.tab-panel {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow: hidden;
  margin-bottom: 44px;

}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.overview {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width:100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
}

.overview__header{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width:100%;
  height: fit-content;
  margin-left: 0;
  margin-right: auto;
}

.title-admin {
  /* KOL / Digital influencers */
  margin-top: 1rem;
  width: 20.375rem;
  height: 2.375rem;

  top: 7rem;
  margin-right: auto;
  margin-left: 1rem;

  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 38px;

  /* Primary - DarkBlue */
  color: #001945;
}

.tab-options-admin {
  z-index:1;
  cursor: pointer;
  margin-left: auto;
  margin-right: auto;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  width: 16.75rem;
  height: 2.625rem;
  margin-top: 1rem;
  justify-content: space-between;
  padding: 0.5rem;
  align-items: center;

  border: 1px solid var(--primary----blue);
  box-sizing: border-box;
  border-radius: 8px;
  background: var(--primary----blue-ultra-light);

  /* identical to box height, or 129% */
  display: flex;
  align-items: center;
  letter-spacing: 0.1px;

  /* Primary - Blue */
  color: var(--primary----blue);
  /* Primary - Blue 3 */
}

.tab-labels {
  background-color: var(--back);
  box-shadow: none;
}

.tab-button {
  background-color: var(--back);
  border-radius: 0;
  box-shadow: none;
}

.MuiTouchRipple-root {
  box-shadow: none;
  background: transparent;
}

.tab-button.Mui-selected {
  background: transparent;

  font-weight: 600;
  background-color: var(--back);
  box-shadow: none;
  border: none;
}

.tabs {
  margin-top: 1rem;
}

.grid-table-admin {
  align-self: start;
  flex-direction: column;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-gap: 1rem;
  width: 100%;
  height: 100%;
  padding-left: 2rem;
  padding-right: 2rem;
  margin-left: auto;
  margin-right: auto;
}

.container-admin {
  background-color: --back;
  margin-top: 5.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.grid-filter-admin {
  z-index: 1;
  padding: 2rem;
  margin-top: -1rem;
  margin-left: -1rem;
  min-width: 23rem;
  width: 20vw;
  height: 100vh;
  display: flex;
  flex-direction: column!important;
  background: var(--white);
  margin-bottom: auto;
}
