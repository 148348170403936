.ant-btn.follow {
    color: var(--primary----blue);
    background: var(--white);
    border: 1px solid var(--primary----blue-3);
    border-radius: 4px;
    width: 92px;
    height: 32px;
    padding: 0px 16px;
    text-transform: capitalize;
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    cursor: pointer;
}

/* on hover or focus */
.ant-btn.follow:hover, .follow:focus {
    color: var(--primary----blue);
    border: 1px solid var(--primary----blue-2);
    background: var(--white);
}

/* on disabled */
.ant-btn.follow:disabled {
    color: var(--primary----blue-2);
    border: 1px solid var(--primary----blue-3);
    background: var(--white);
}

.ant-btn.following {
    color: var(--primary----blue);
    background: var(--primary----blue-ultra-light);
    border: 1px solid var(--primary----blue-3);
    border-radius: 4px;
    width: 92px;
    height: 32px;
    padding: 0px 16px;
    text-transform: capitalize;
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    cursor: pointer;
}

/* on hover or focus */
 .ant-btn.following:focus {
    color: var(--primary----blue);
    background: var(--primary----blue-3);
    border: 1px solid var(--primary----blue-3);
}

/* on disabled */
.ant-btn.following:disabled {
    color: var(--primary----blue-2);
    border: 1px solid var(--primary----blue-3);
    background: var(--white);
}

.ant-btn.following-waiting {
    cursor: progress !important;
}

.ant-btn.following:hover, .ant-btn.unfollow {
    color: var(--negative);
    background: var(--white);
    border: 1px solid var(--negative);
    border-radius: 4px;
    width: 92px;
    height: 32px;
    padding: 0px 16px;
    text-transform: capitalize;
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    cursor: pointer;
    
}
