:root {
    /* Color styles */
--primary----dark-blue: rgba(32, 21, 71, 1);
--primary----blue: rgba(84, 26, 195, 1);
--primary----blue-1: rgba(139, 89, 234, 1);
--primary----blue-2: rgba(175, 136, 248, 1);
--primary----blue-3: rgba(207, 184, 251, 1);
--primary----blue-ultra-light: rgba(223, 207, 252, 1);
--secondary-1: rgba(84, 26, 195, 1);
--secondary-2: rgba(139, 89, 234, 1);
--secondary-3: rgba(175, 136, 248, 1);
--secondary-4: rgba(229, 247, 253, 1);
--gray---1: rgba(67, 67, 67, 1);
--gray---2: rgba(100, 100, 100, 1);
--gray---3: rgba(168, 168, 168, 1);
--gray---4: rgba(213, 212, 212, 1);
--gray---5: rgba(233, 233, 233, 1);
--back: rgba(252, 252, 252, 1);
--white: rgba(255, 255, 255, 1);
--black: rgba(0, 0, 0, 1);
--acent---1: rgba(255, 107, 0, 1);
--acent---2: rgba(0, 160, 219, 1);
--acent---3: rgba(120, 39, 133, 1);
--acent---4: rgba(16, 122, 53, 1);
--acent---5: rgba(36, 61, 86, 1);
--acent---6: rgba(156, 55, 0, 1);
--acent---7: rgba(5, 100, 99, 1);
--acent---8: rgba(115, 79, 3, 1);
--acent--1-back: rgba(255, 225, 204, 1);
--acent--2-back: rgba(229, 247, 254, 1);
--acent--3-back: rgba(245, 228, 248, 1);
--acent--4-back: rgba(234, 251, 240, 1);
--acent--5-back: rgba(235, 243, 251, 1);
--acent--6-back: rgba(255, 240, 232, 1);
--acent--7-back: rgba(214, 253, 253, 1);
--acent--8-back: rgba(254, 241, 211, 1);
--acent-2-light: rgba(249, 240, 255, 1);
--acent-2-medium: rgba(231, 203, 250, 1);
--positive: rgba(4, 158, 58, 1);
--negative: rgba(239, 45, 45, 1);

/* Text-size styles */
--h1: 28px;
--h2: 20px;
--h2-card: 20px;
--h3: 16px;
--h4: 16px;
--p1: 14px;
--p2: 14px;
--p3: 14px;
--t1: 12px;
--t2: 12px;
--c1: 12px;

/* Effect styles */
--shadow--card--default:  2px 2px 8px rgba(151, 160, 170, 0.3);
--shadow--card--hover:  2px 2px 14px rgba(90, 104, 169, 0.3);
--shadow-drop--filter:  0px 0px 20px rgba(0, 53, 149, 0.25);


}