.ant-tooltip .ant-tooltip-inner {
    background: var(--primary----dark-blue);
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 10px;
    gap: 8px;
    width: 180px;
    height: auto;
    box-shadow: var(--shadow-md);
    border-radius: 2px;
}